/*
 |-----------------------------------------------------------------------------
 | features/projects/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setProjects = createAction<Array<object>>('projects');

/*
 |-----------------------------------------------------------------------------
 | features/certifications/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectCertifications = (state: RootState) =>
	state.certifications.data;

export const certificationsSelector = createSelector(
	selectCertifications,
	state => state
);

/*
 |-----------------------------------------------------------------------------
 | features/menu/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectMenu = (state: RootState) => state.menu.isOpen;

export const menuSelector = createSelector(selectMenu, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/reviews/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setReviews = createAction<Array<object>>('reviews');

/*
 |-----------------------------------------------------------------------------
 | features/recipes/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectRecipes = (state: RootState) => state.recipes.data;

export const recipesSelector = createSelector(selectRecipes, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/articles/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setReviews } from './actions';

type ReviewsState = {
	data: Array<object>;
};

const initialState: ReviewsState = {
	data: [],
};

export const reviewsReducer = createReducer(initialState, builder => {
	builder.addCase(setReviews, (state, action) => {
		state.data = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | features/collection/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setCollection } from './actions';

type CollectionState = {
	value: string;
};

const initialState: CollectionState = {
	value: '',
};

export const collectionReducer = createReducer(initialState, builder => {
	builder.addCase(setCollection, (state, action) => {
		state.value = action.payload;
	});
});

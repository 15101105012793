/*
 |-----------------------------------------------------------------------------
 | features/modal/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectModal = (state: RootState) => state.modal.isOpen;

export const modalSelector = createSelector(selectModal, state => state);

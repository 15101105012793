/*
 |-----------------------------------------------------------------------------
 | features/menus/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectMenus = (state: RootState) => state.menus.data;

export const menusSelector = createSelector(selectMenus, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/people/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectPeople = (state: RootState) => state.people.data;

export const peopleSelector = createSelector(selectPeople, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/services/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setServices = createAction<Array<object>>('services');

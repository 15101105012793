/*
 |-----------------------------------------------------------------------------
 | features/menus/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setMenus = createAction<Array<object>>('menus');

/*
 |-----------------------------------------------------------------------------
 | features/patrons/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectPatrons = (state: RootState) => state.patrons.data;

export const patronsSelector = createSelector(selectPatrons, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/certifications/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setCertifications } from './actions';

type CertificationsState = {
	data: Array<object>;
};

const initialState: CertificationsState = {
	data: [],
};

export const certificationsReducer = createReducer(initialState, builder => {
	builder.addCase(setCertifications, (state, action) => {
		state.data = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | features/menu/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { toggleMenu } from './actions';

type MenuState = {
	isOpen: boolean;
};

const initialState: MenuState = {
	isOpen: false,
};

export const menuReducer = createReducer(initialState, builder => {
	builder.addCase(toggleMenu, (state, action) => {
		state.isOpen = action.payload;
	});
});

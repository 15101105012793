/*
 |-----------------------------------------------------------------------------
 | features/articles/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setArticles } from './actions';

type ArticlesState = {
	data: Array<object>;
};

const initialState: ArticlesState = {
	data: [],
};

export const articlesReducer = createReducer(initialState, builder => {
	builder.addCase(setArticles, (state, action) => {
		state.data = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | features/modal/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { toggleModal } from './actions';

type ModalState = {
	isOpen: boolean;
};

const initialState: ModalState = {
	isOpen: false,
};

export const modalReducer = createReducer(initialState, builder => {
	builder.addCase(toggleModal, (state, action) => {
		state.isOpen = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | features/organisation/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectOrganisation = (state: RootState) => state.organisation.data;

export const organisationSelector = createSelector(
	selectOrganisation,
	state => state
);

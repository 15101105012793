/*
 |-----------------------------------------------------------------------------
 | features/jobs/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setJobs } from './actions';

type JobsState = {
	data: Array<object>;
};

const initialState: JobsState = {
	data: [],
};

export const jobsReducer = createReducer(initialState, builder => {
	builder.addCase(setJobs, (state, action) => {
		state.data = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | app/store.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import { articlesReducer } from '@/features/articles';
import { certificationsReducer } from '@/features/certifications';
import { collectionReducer } from '@/features/collection';
import { eventsReducer } from '@/features/events';
import { jobsReducer } from '@/features/jobs';
import { menuReducer } from '@/features/menu';
import { menusReducer } from '@/features/menus';
import { modalReducer } from '@/features/modal';
import { organisationReducer } from '@/features/organisation';
import { pageReducer } from '@/features/page';
import { patronsReducer } from '@/features/patrons';
import { peopleReducer } from '@/features/people';
import { postsReducer } from '@/features/posts';
import { productsReducer } from '@/features/products';
import { projectsReducer } from '@/features/projects';
import { recipesReducer } from '@/features/recipes';
import { reviewsReducer } from '@/features/reviews';
import { servicesReducer } from '@/features/services';
import { stockistsReducer } from '@/features/stockists';

export const store = configureStore({
	reducer: {
		articles: articlesReducer,
		certifications: certificationsReducer,
		collection: collectionReducer,
		events: eventsReducer,
		jobs: jobsReducer,
		menu: menuReducer,
		menus: menusReducer,
		modal: modalReducer,
		organisation: organisationReducer,
		page: pageReducer,
		patrons: patronsReducer,
		people: peopleReducer,
		posts: postsReducer,
		products: productsReducer,
		projects: projectsReducer,
		recipes: recipesReducer,
		reviews: reviewsReducer,
		services: servicesReducer,
		stockists: stockistsReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

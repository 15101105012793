/*
 |-----------------------------------------------------------------------------
 | features/page/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectPage = (state: RootState) => state.page.data;

export const pageSelector = createSelector(selectPage, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/page/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setPage } from './actions';

type PageState = {
	data: object;
};

const initialState: PageState = {
	data: {},
};

export const pageReducer = createReducer(initialState, builder => {
	builder.addCase(setPage, (state, action) => {
		state.data = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | features/jobs/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectJobs = (state: RootState) => state.jobs.data;

export const jobsSelector = createSelector(selectJobs, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/certifications/index.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

export * from './actions';
export * from './reducer';
export * from './selectors';

/*
 |-----------------------------------------------------------------------------
 | features/page/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setPage = createAction<object>('page');

/*
 |-----------------------------------------------------------------------------
 | features/services/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectServices = (state: RootState) => state.services.data;

export const servicesSelector = createSelector(selectServices, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/articles/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectArticles = (state: RootState) => state.articles.data;

export const articlesSelector = createSelector(selectArticles, state => state);

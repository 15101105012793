/*
 |-----------------------------------------------------------------------------
 | features/events/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectEvents = (state: RootState) => state.events.data;

export const eventsSelector = createSelector(selectEvents, state => state);

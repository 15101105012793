/*
 |-----------------------------------------------------------------------------
 | features/products/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectProducts = (state: RootState) => state.products.data;

export const productsSelector = createSelector(selectProducts, state => state);

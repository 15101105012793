/*
 |-----------------------------------------------------------------------------
 | features/stockists/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectStockists = (state: RootState) => state.stockists.data;

export const stockistsSelector = createSelector(
	selectStockists,
	state => state
);

/*
 |-----------------------------------------------------------------------------
 | features/articles/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectPosts = (state: RootState) => state.posts.data;

export const postsSelector = createSelector(selectPosts, state => state);

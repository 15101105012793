/*
 |-----------------------------------------------------------------------------
 | features/projects/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectProjects = (state: RootState) => state.projects.data;

export const projectsSelector = createSelector(selectProjects, state => state);

/*
 |-----------------------------------------------------------------------------
 | features/certifications/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setCertifications = createAction<Array<object>>('certifications');

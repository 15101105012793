/*
 |-----------------------------------------------------------------------------
 | features/projects/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setProjects } from './actions';

type ProjectsState = {
	data: Array<object>;
};

const initialState: ProjectsState = {
	data: [],
};

export const projectsReducer = createReducer(initialState, builder => {
	builder.addCase(setProjects, (state, action) => {
		state.data = action.payload;
	});
});

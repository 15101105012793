/*
 |-----------------------------------------------------------------------------
 | features/collection/selector.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

export const selectCollection = (state: RootState) => state.collection.value;

export const collectionSelector = createSelector(
	selectCollection,
	state => state
);

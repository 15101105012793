/*
 |-----------------------------------------------------------------------------
 | features/organisation/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setOrganisation } from './actions';

type OrganisationState = {
	data: object;
};

const initialState: OrganisationState = {
	data: {},
};

export const organisationReducer = createReducer(initialState, builder => {
	builder.addCase(setOrganisation, (state, action) => {
		state.data = action.payload;
	});
});

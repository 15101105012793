/*
 |-----------------------------------------------------------------------------
 | features/stockists/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setStockists } from './actions';

type stockistsState = {
	data: Array<object>;
};

const initialState: stockistsState = {
	data: [],
};

export const stockistsReducer = createReducer(initialState, builder => {
	builder.addCase(setStockists, (state, action) => {
		state.data = action.payload;
	});
});

/*
 |-----------------------------------------------------------------------------
 | features/articles/reducer.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createReducer } from '@reduxjs/toolkit';

import { setPatrons } from './actions';

type PatronsState = {
	data: Array<object>;
};

const initialState: PatronsState = {
	data: [],
};

export const patronsReducer = createReducer(initialState, builder => {
	builder.addCase(setPatrons, (state, action) => {
		state.data = action.payload;
	});
});

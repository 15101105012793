/*
 |-----------------------------------------------------------------------------
 | features/patrons/actions.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { createAction } from '@reduxjs/toolkit';

export const setPatrons = createAction<Array<object>>('patrons');
